<template>
  <v-container fluid class="px-0">
    <v-row class="align-end" dense>
      <v-col>
        <v-file-input
          v-if="!localFile.filename"
          accept=".xlsx"
          show-size
          hide-details
          @change="selectFile"
          :disabled="logView || !editMode"
          :loading="updating"
          label="Выберете файл справочника в формате Excel, чтобы обновить данные"
        />
        <div v-else class="d-flex text-wrap align-end justify-lg-space-between">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                @click="downloadFile"
                v-bind="attrs"
                v-on="on"
                class="link blue--text font-italic"
              >
                <v-icon class="blue--text" left>mdi-file-excel-outline</v-icon
                >{{ localFile.filename }}
              </span>
            </template>
            <span
              >Скачать файл<v-icon color="white" right
                >mdi-download</v-icon
              ></span
            >
          </v-tooltip>
          <v-file-input
            v-if="!logView && editMode"
            class="justify-end"
            accept=".xlsx"
            hide-input
            show-size
            hide-details
            @change="selectFile"
            :loading="updating"
            title="Выберете файл справочника в формате Excel, чтобы обновить данные"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="showLocalFile" dense>
      <v-col cols="4" v-if="localFile.filename">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              @click="downloadFile"
              v-bind="attrs"
              v-on="on"
              class="link blue--text font-italic"
            >
              <v-icon class="blue--text" left>mdi-file-excel-outline</v-icon
              >{{ localFile.filename }}
            </span>
          </template>
          <span
            >Скачать файл<v-icon color="white" right>mdi-download</v-icon></span
          >
        </v-tooltip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import * as Exceljs from 'exceljs'
import {
  DEFAULT_CALCULATION_COST,
  DEFAULT_COMBINE_INDICATION,
  DEFAULT_INDICATION,
} from '@/components/expCost/const'
import { copyObject } from '@/lib/objects'

export default {
  name: 'UpdateCost',
  data: () => ({
    loading: true,
    file: null,
    updating: false,
    showLocalFile: false,
  }),
  props: {
    logView: {
      type: Boolean,
      default: false,
    },
    vzn: {
      type: Object,
      default: () => {},
    },
    gnvlp: {
      type: Object,
      default: () => {},
    },
    localFile: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    checkUpdateError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['storedPlpId']),
  },
  methods: {
    ...mapActions([
      'LOAD_KEI_TEMPLATE',
      'SET_ERROR',
      'CREATE_PLP_FILE',
      'GET_PLP_FILE',
    ]),
    ...mapMutations(['SET_ERROR']),
    doChange(data, force = false) {
      if (!data.gnvlp?.length && !data?.vzn?.length) this.$emit('error')
      if (!this.readonly) this.$emit('change', data, force)
    },
    // ушли с элемента
    doBlur() {
      this.$emit('blur')
    },
    async downloadFile() {
      await this.GET_PLP_FILE(this.localFile)
    },
    checkFooter(row) {
      const cell = row.model.cells[0]
      return cell?.value?.includes('Примечания')
    },
    getIdx(sheet) {
      let footerIdx
      let indicationIdx
      sheet.eachRow((row, idx) => {
        if (this.checkFooter(row)) {
          footerIdx = idx
        }
        if (row.model?.cells[1]?.value && !indicationIdx) {
          indicationIdx = idx
        }
      })
      return { footerIdx, indicationIdx }
    },
    //Примечания
    getValuesFooters(sheet, footerIdx) {
      const valuesFooters = []
      for (let i = footerIdx; i < sheet.rowCount + 1; i++) {
        const row = sheet.getRow(i)?.values
        if (!row.length) break
        valuesFooters.push(row[1])
      }
      return valuesFooters
    },
    //Показания сверху таблицы
    getValuesIndications(sheet, indicationIdx) {
      const valuesIndications = []
      for (let i = 0; i < indicationIdx; i++) {
        const row = sheet.getRow(i)?.values
        if (row[1]) valuesIndications.push(row[1])
      }
      return valuesIndications
    },
    getMergedValues(inObj) {
      const obj = copyObject(inObj)
      if (!obj) return null
      if (obj.top === obj.bottom) {
        obj.top = 0
        obj.bottom = 0
      }
      if (obj.left === obj.right) {
        obj.left = 0
        obj.right = 0
      }
      if (obj.bottom > obj.top) {
        obj.bottom = obj.bottom - obj.top
        obj.top = 0
      } else if (obj.bottom < obj.top) {
        obj.top = obj.top - obj.bottom
        obj.bottom = 0
      }
      if (obj.right > obj.left) {
        obj.right = obj.right - obj.left
        obj.left = 0
      } else if (obj.right < obj.left) {
        obj.left = obj.left - obj.right
        obj.right = 0
      }
      return obj
    },
    //Полезные данные таблицы
    getTableData(sheet, footerIdx, indicationIdx, withCombine = false) {
      const tableData = []
      const mergeObj = sheet._merges || []

      for (let i = indicationIdx + 1; i < footerIdx; i++) {
        const item = withCombine
          ? DEFAULT_COMBINE_INDICATION()
          : DEFAULT_INDICATION()
        const row = sheet.getRow(i)?._cells || []

        Object.keys(item).forEach((key, idx) => {
          //идем за результатом, если его нет то значение, если его нет то прочерк
          item[key].value = row[idx]?.value?.result || row[idx]?.value || '-'

          //Собираем объект item со значением и информаций о объединенных ячейках(merge ячейка объединена, mergeObj у объединенного объекта-родителя)
          const colRowSpans = this.getMergedValues(
            mergeObj[row[idx]?.address]?.model
          )
          if (colRowSpans) {
            item[key].mergeObj = colRowSpans
          }
          item[key].merged = row[idx]?.isMerged || false
        })
        tableData.push(item)
      }
      return tableData
    },
    getHeaders(sheet, indicationIdx) {
      const headers = sheet.getRow(indicationIdx)?.values || []
      headers.shift()
      return headers
    },
    //Функция, собирающая все данные для формирования таблицы
    getTable(sheet) {
      const { footerIdx, indicationIdx } = this.getIdx(sheet)
      const headers = this.getHeaders(sheet, indicationIdx)
      const withCombine = headers[1].includes('Наименование комбинации')

      return {
        name: sheet.name || '',
        valuesFooters: this.getValuesFooters(sheet, footerIdx),
        valuesIndications: this.getValuesIndications(sheet, indicationIdx),
        tableData: this.getTableData(
          sheet,
          footerIdx,
          indicationIdx,
          withCombine
        ),
        headers,
      }
    },
    collectLists(workbook, name) {
      const out = []
      const regex = new RegExp(
        `^\\s*Результаты*\\s*\\(${name}\\)*\\s*(?:[-_~]*\\s*(?<digit>\\d+)|)(?:|\\s+.*)$`
      )
      workbook.eachSheet(sheet => {
        if (regex.test(sheet.name)) {
          out.push(this.getTable(sheet))
        }
      })
      return out
    },
    collectCalculations(list) {
      const out = []
      list.forEach(() => {
        out.push({
          name: '',
          indication: '',
          calculation: '',
          costs: [DEFAULT_CALCULATION_COST()],
        })
      })
      return out
    },
    async compileManual(changeFile = false) {
      let localFile = null

      const workbook = new Exceljs.Workbook()

      await workbook.xlsx.load(this.file)

      const gnvlp = this.collectLists(workbook, 'ЖНВЛП')
      const vzn = this.collectLists(workbook, 'ВЗН')

      const gnvlpCalculations = this.collectCalculations(gnvlp)
      const vznCalculations = this.collectCalculations(vzn)

      if (changeFile) {
        localFile = await this.CREATE_PLP_FILE({
          plp_id: this.storedPlpId,
          filename: this.file.name,
          file: this.file,
        })
      }
      this.doChange({
        gnvlp,
        gnvlpCalculations,
        vzn,
        vznCalculations,
        localFile,
      })
    },
    async selectFile(file) {
      this.file = file
      if (this.file) {
        await this.compileManual(true)
      }
    },
    getIndications(sheet, type = 'gnvlp') {
      const indications = []
      const cost = type === 'vzn' ? this.vzn : this.gnvlp
      const costLabel = type === 'vzn' ? 'ВЗН' : 'ЖНВЛП'
      if (cost.count === 1) {
        indications.push(this.getTable(sheet))
      } else {
        for (let i = 1; i < cost.count + 1; i++) {
          if (sheet.name === `Результаты (${costLabel})-${i}`) {
            indications.push(this.getTable(sheet))
          }
        }
      }

      return indications
    },
  },
}
</script>

<style scoped>
.link {
  cursor: pointer;
}
</style>
